import React from 'react';
import Layout from '../components/layout/layout';
import HomePage from "./home";

const IndexPage = () => (
  <Layout noBg home>
    <HomePage />
  </Layout>
)

export default IndexPage;
